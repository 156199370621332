export const splitAt = (index: number, s: string) => [
  s.slice(0, index),
  s.slice(index),
]

export function maskPhoneNumber(number: string) {
  if (!number) throw new Error('Vul een telefoonnummer in')
  const splitted = splitAt(number.length - 2, number)
  const masked = splitted[0].replace(/./g, '*') + splitted[1] // output: **********60
  return masked
}

export const truncateText = (txt: string, length = 110) => {
  if (txt.length <= length) return txt
  const truncate = txt.substring(0, length) + '...'
  return truncate
}

export const removeWhitespace = (str: string) => {
  return str.replace(/\s+/g, '')
}

export const convertToCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export function convertToSlug(s: string) {
  return s.toLowerCase().replace(/ /g, '-')
}
