import { useEffect, useState } from 'react'
import axios from 'axios'

import { GRID, GRID_ITEM_FULL_WIDTH } from '@/lib/constants'
import { VacancyDetailsCard } from '@/interfaces/vacancy'
import {
  LandingPageVacancyIDsCollection,
  TalentProgramVacancyIdCollection,
  VacancyIdCollection,
  WerkenBijPage,
  WerkenBijPageVacancyIdCollection,
} from '@/interfaces/schema'
import CTAVacanciesCard from '@/components/cards/cta/cta-vacancies'
import Section from '@/layouts/section'
import Block from '@/layouts/block'
import { VacanciesCardLoader } from '@/components/loader'
import IconWithTitle from '@/components/icon-with-title'
import { API_ENDPOINTS } from '@/services/serverless/api/config'
import VacanciesList from '@/components/vacancy-overview/vacancy-list'

type Props = Pick<
  WerkenBijPage,
  'ctaVacanciesCard' | 'vacanciesSectionTitle' | 'vacanciesSectionIcon'
> & {
  className?: string
  id?: string
  vacancyIdCollection:
    | VacancyIdCollection
    | TalentProgramVacancyIdCollection
    | WerkenBijPageVacancyIdCollection
    | LandingPageVacancyIDsCollection
    | undefined
}

export default function VacanciesAndCtaSection({
  vacanciesSectionTitle = 'Onze vacatures',
  vacanciesSectionIcon,
  ctaVacanciesCard,
  vacancyIdCollection,
  className = '',
  id = '',
}: Props) {
  const [vacancies, setVacancies] = useState<VacancyDetailsCard[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // Fetch max of two vacancies
  useEffect(() => {
    async function fetchVacancies(vidSelection: string[]) {
      const vacancies = axios.post(API_ENDPOINTS.VACANCY.SELECT, {
        vidSelection,
      })

      return vacancies
    }

    if (vacancyIdCollection?.items) {
      setIsLoading(true)

      fetchVacancies([
        vacancyIdCollection?.items[0].vacancyId ?? '',
        vacancyIdCollection?.items[1].vacancyId ?? '',
      ])
        .then((res) => {
          if (res.data.result.data) setVacancies(res.data.result.data)
        })
        .catch((err) => {
          console.error('err :>> ', err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [vacancyIdCollection?.items])

  return (
    <Section id={id} className={`${className} mb-[30px] lg:mb-[120px] w-full`}>
      <IconWithTitle
        className="mb-[30px]"
        icon={vacanciesSectionIcon}
        title={vacanciesSectionTitle}
      />
      <Block className={`${GRID_ITEM_FULL_WIDTH} ${GRID}`}>
        {isLoading && (
          <>
            <VacanciesCardLoader />
            <VacanciesCardLoader />
          </>
        )}
        <VacanciesList
          className={`col-span-full ${GRID} `}
          vacancies={vacancies ?? null}
          ctaCard={
            <CTAVacanciesCard
              className="threeCardsCtaCard col-span-2 md:col-span-3 lg:col-span-4"
              title={ctaVacanciesCard?.title}
              description={ctaVacanciesCard?.description}
              buttonText={ctaVacanciesCard?.buttonText}
              buttonPath={ctaVacanciesCard?.buttonPath}
            />
          }
        />
      </Block>
    </Section>
  )
}
