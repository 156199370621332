import { ButtonLinkTertiary } from '@/components/button'
import { Divider, DividerColors } from '@/components/divider'
import { H3, P1 } from '@/components/typography'
import Block from '@/layouts/block'
import { routes } from '@/lib/constants'
import { truncateText } from '@/lib/string'

type Props = {
  className?: string
  title?: string
  description?: string
  buttonText?: string
  buttonPath?: string
}

const CTAVacanciesCard = ({
  className = '',
  title = 'Bekijk al onze vacatures',
  description = '',
  buttonText = 'Vacature overzicht',
  buttonPath = '',
}: Props) => {
  return (
    <Block className={`ctaVacanciesCard ${className}`}>
      <H3>{title}</H3>
      <P1>{truncateText(description, 150)}</P1>
      <div>
        <Divider color={DividerColors.WHITE} />
        <ButtonLinkTertiary
          href={buttonPath ?? `${routes.rvdb.vacancies}`}
          version="b"
        >
          {buttonText}
        </ButtonLinkTertiary>
      </div>
    </Block>
  )
}

export default CTAVacanciesCard
