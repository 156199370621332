import * as React from 'react'
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress'

import MuiPalette from '@/components/mui-theme'

export default function LinearLoader({
  color = 'inherit',
}: {
  color?: LinearProgressProps['color']
}) {
  return (
    <div className="w-screen absolute top-0 left-0 z-10">
      <MuiPalette>
        <LinearProgress color={color} />
      </MuiPalette>
    </div>
  )
}
